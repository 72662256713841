*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    background-color: #222121;
    font-family: 'Barlow', sans-serif;
    font-family: 'Comfortaa', cursive;
    font-family: 'Electrolize', sans-serif;
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Playfair Display', serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;

}
ul{
    padding: 0;
    margin: 0;
}
a{
    text-decoration: none;
    color: white;
}